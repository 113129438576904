import axios from 'axios';
import { trim } from './util';
import { Message } from 'element-ui';
import router from '@/router';
import token from '../config//token';
import baseUrl from '../config/baseUrl'
import { getToken } from '@/util/cookie';
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
const axiosInstance = axios.create({
  baseURL: baseUrl,
  // 请求超时时间
  timeout: 900000,
});
let lock = false;
let tipLock = false;

// 添加请求拦截器
axiosInstance.interceptors.request.use(
  function (config) {
    // 参数去空格
    if (config.trim === true) {
      if (config.data != null) {
        config.data = trim(config.data);
      }
      if (config.params != null) {
        config.params = trim(config.params);
      }
    }
    // config.headers.token = token;
    let token = getToken() || '';
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  },
);

// 添加响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    // 请求失败
    if (response.status !== 200) {
      return Promise.reject(response.data);
    }
    // 业务失败
    if (response.data.code !== 200) {
      if (response.headers['content-type'] === 'application/vnd.ms-excel;charset=utf-8')
        return response;
      Message.error(response.data.message);
      // 未登录
      if (response.data.code === 401) {
        if (lock) return;
        if (router.app.$route.name === 'login') {
          location.reload();
          return;
        }
        lock = true;
        router.push('/login');
        lock = false;
      }
      if (response.data.code == '420') {
        const message =
          '系统停服维护中，请稍候勿关闭页面。恢复后可重新提交，关注【鱼开店】群获最新动态。';
        if (!tipLock) {
          Message.$alert(message)
            .then(() => {
              tipLock = false;
            })
            .catch(() => {
              tipLock = false;
            });
          tipLock = true;
        }
      }
      return Promise.reject(response.data);
    }
    return response.data.data;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  },
);

export default axiosInstance;

//
export const getUploadheader = (headers) => {
  let token = getToken() || '';
  let baseHeader = {
    Authorization: `Bearer ${token}`,
  };
  return {
    ...baseHeader,
    ...headers,
  };
};