// 流程模板构建页面
const draw = () => import(/* webpackChunkName: "draw" */ '@/pages/workflow/template/draw/index');
// 流程模板构建页面
const nodeConfiguration = () => import(/* webpackChunkName: "nodeConfiguration" */ '@/pages/workflow/template/nodeConfiguration/index');
// 流程模板构建页面
const fieldLibrary = () =>
  import(/* webpackChunkName: "fieldLibrary" */ '@/pages/workflow/template/draw/formDesign/index');

// 流程模板列表
const workflowTemplateList = () =>
  import(/* webpackChunkName: "workflowTemplateList" */ '@/pages/workflow/template/list/index');
// 异常管理列表
const workflowNodeMngList = () =>
  import(/* webpackChunkName: "workflowNodeMngList" */ '@/pages/workflow/nodeMng/list/index');
// 开店概览
const buildStoreOverview = () =>
  import(/* webpackChunkName: "buildStoreChildProcess" */ '@/pages/workflow/overview/index');
// 数据管理列表
const workflowDataMngList = () =>
  import(/* webpackChunkName: "workflowDataMngList" */ '@/pages/workflow/dataMng/list/index');
// 数据管理列表
const workflowDataMngDetail = () =>
  import(/* webpackChunkName: "workflowDataMngDetail" */ '@/pages/workflow/dataMng/detail/index');
// 数据管理列表
const workflowDataMngConfigDetail = () =>
  import(
    /* webpackChunkName: "workflowDataMngConfigDetail" */ '@/pages/workflow/dataMng/configDetail/index'
  );

// 我的待办
const todoList = () =>
  import(/* webpackChunkName: "todoList" */ '@/pages/workflow/todoList/home/index');
// 待办详情
const todoListDetail = () =>
  import(/* webpackChunkName: "todoListDetail" */ '@/pages/workflow/todoList/detail/index');
// 我的已办
const sentList = () =>
  import(/* webpackChunkName: "sentList" */ '@/pages/workflow/sentList/home/index');
// 已办详情
const sentListDetail = () =>
  import(/* webpackChunkName: "sentListDetail" */ '@/pages/workflow/sentList/detail/index');
// 我的已办
const readList = () =>
  import(/* webpackChunkName: "readList" */ '@/pages/workflow/readList/home/index');
// 已办详情
const readListDetail = () =>
  import(/* webpackChunkName: "readListDetail" */ '@/pages/workflow/readList/detail/index');
// 我的已办
const doneList = () =>
  import(/* webpackChunkName: "doneList" */ '@/pages/workflow/doneList/home/index');
// 已办详情
const doneListDetail = () =>
  import(/* webpackChunkName: "doneListDetail" */ '@/pages/workflow/doneList/detail/index');
// 已办打印
const doneListPrint = () =>
  import(/* webpackChunkName: "doneListPrint" */ '@/pages/workflow/doneList/print/index');
// 开店项目管理
const buildStore = () =>
  import(/* webpackChunkName: "buildStore" */ '@/pages/workflow/buildStore/list/index');
// 项目管控
const projectMonitor = () =>
  import(/* webpackChunkName: "projectMonitor" */ '@/pages/workflow/projectMonitor/list/index');
// 编辑表单数据
const editProjectForm = () =>
  import(/* webpackChunkName: "doneListDetail" */ '@/pages/workflow/buildStore/editForm/index');
// 项目管理详情
const buildStoreDetail = () =>
  import(/* webpackChunkName: "buildStoreDetail" */ '@/pages/workflow/buildStore/detail/index');
// 子流程发起记录
const buildStoreChildProcess = () =>
  import(
    /* webpackChunkName: "buildStoreChildProcess" */ '@/pages/workflow/buildStore/childProcess/index'
  );
// 子流程进度概览页
const buildStoreChildDetail = () =>
  import(
    /* webpackChunkName: "buildStoreChildProcess" */ '@/pages/workflow/buildStore/childProcess/detail'
  );
// 开店概览
const overview = () =>
  import(/* webpackChunkName: "buildStore" */ '@/pages/workflow/overview/index');
// 地图概览
const overviewLight = () =>
  import(/* webpackChunkName: "buildStore" */ '@/pages/workflow/overviewLight/index');

// 任务交接
const taskTransfer = () =>
  import(/* webpackChunkName: "taskTransfer" */ '@/pages/workflow/taskTransfer/index');
// 角色变更
const roleChange = () =>
  import(/* webpackChunkName: "roleChange" */ '@/pages/workflow/roleChange/index');

export default [
  {
    path: '/workflow/overview',
    name: 'buildStoreOverview',
    component: buildStoreOverview,
    meta: {
      pageName: '开店概览',
    },
  },
  {
    path: '/workflow/template/fieldLibrary',
    name: 'fieldLibrary',
    component: fieldLibrary,
    meta: {
      pageName: '字段库',
    },
  },
  {
    path: '/workflow/template/draw',
    name: 'draw',
    component: draw,
    meta: {
      pageName: '流程编辑',
    },
  },
  {
    path: '/workflow/template/nodeConfiguration',
    name: 'nodeConfiguration',
    component: nodeConfiguration,
    meta: {
      pageName: '节点配置',
    },
  },
  {
    path: '/workflow/template/drawDetail',
    name: 'drawDetail',
    component: draw,
    meta: {
      pageName: '流程详情',
    },
  },
  {
    path: '/workflow/template/list',
    name: 'workflowTemplateList',
    component: workflowTemplateList,
    meta: {
      keepAlive: true,
      pageName: '流程管理',
    },
  },
  {
    path: '/workflow/nodeMng/list',
    name: 'workflowNodeMngList',
    component: workflowNodeMngList,
    meta: {
      pageName: '节点管控',
    },
  },
  {
    path: '/workflow/dataMng/list',
    name: 'workflowDataMngList',
    component: workflowDataMngList,
    meta: {
      keepAlive: true,
      pageName: '数据管理',
    },
  },
  {
    path: '/workflow/dataMng/detail',
    name: 'workflowDataMngDetail',
    component: workflowDataMngDetail,
    meta: {
      pageName: '报表详情',
    },
  },
  {
    path: '/workflow/dataMng/configDetail',
    name: 'workflowDataMngConfigDetail',
    component: workflowDataMngConfigDetail,
    meta: {
      pageName: '报表编辑',
    },
  },
  {
    path: '/workflow/todoList/home',
    name: 'todoList',
    component: todoList,
    meta: {
      keepAlive: true,
      pageName: '待办',
    },
  },
  {
    path: '/workflow/todoList/detail',
    name: 'todoListDetail',
    component: todoListDetail,
    meta: {
      pageName: '待办详情',
    },
  },
  {
    path: '/workflow/buildStore/list',
    name: 'buildStore',
    component: buildStore,
    meta: {
      keepAlive: true,
      pageName: '项目列表',
    },
  },
  {
    path: '/workflow/projectMonitor/list',
    name: 'projectMonitor',
    component: projectMonitor,
    meta: {
      keepAlive: true,
      pageName: '项目管控',
    },
  },
  {
    path: '/workflow/projectMonitor/editForm',
    name: 'editProjectForm',
    component: editProjectForm,
    meta: {
      pageName: '编辑表单',
    },
  },

  {
    path: '/workflow/buildStore/detail',
    name: 'buildStoreDetail',
    component: buildStoreDetail,
    meta: {
      // keepAlive: true,
      pageName: '项目详情',
    },
  },
  {
    path: '/workflow/buildStore/childProcess/index',
    name: 'buildStoreChildProcess',
    component: buildStoreChildProcess,
    meta: {
      pageName: '子流程',
    },
  },
  {
    path: '/workflow/buildStore/childProcess/detail',
    name: 'buildStoreChildDetail',
    component: buildStoreChildDetail,
    meta: {
      pageName: '子流程进度概览',
    },
  },
  {
    path: '/workflow/doneList/home',
    name: 'doneList',
    component: doneList,
    meta: {
      keepAlive: true,
      pageName: '已办',
    },
  },
  {
    path: '/workflow/doneList/detail',
    name: 'doneListDetail',
    component: doneListDetail,
    meta: {
      pageName: '已办详情',
    },
  },
  {
    path: '/workflow/readList/home',
    name: 'readList',
    component: readList,
    meta: {
      keepAlive: true,
      pageName: '已阅',
    },
  },
  {
    path: '/workflow/readList/detail',
    name: 'readListDetail',
    component: readListDetail,
    meta: {
      pageName: '已阅详情',
    },
  },
  {
    path: '/workflow/sentList/home',
    name: 'sentList',
    component: sentList,
    meta: {
      keepAlive: true,
      pageName: '抄送',
    },
  },
  {
    path: '/workflow/sentList/detail',
    name: 'sentListDetail',
    component: sentListDetail,
    meta: {
      pageName: '抄送详情',
    },
  },
  {
    path: '/workflow/doneList/print',
    name: 'doneListDetail',
    component: doneListPrint,
    meta: {
      pageName: '打印预览',
    },
  },
  {
    path: '/workflow/overview',
    name: 'overview',
    component: overview,
    meta: {
      pageName: '开店概览',
    },
  },
  {
    path: '/workflow/overviewLight',
    name: 'overviewLight',
    component: overviewLight,
    meta: {
      pageName: '开店概览',
    },
  },
  {
    path: '/workflow/taskTransfer',
    name: 'taskTransfer',
    component: taskTransfer,
    meta: {
      keepAlive: true,
      pageName: '人员交接',
    },
  },
  {
    path: '/workflow/roleChange',
    name: 'roleChange',
    component: roleChange,
    meta: {
      keepAlive: true,
      pageName: '角色变更',
    },
  },
];
