let timeout = null

// 去空
export function trim(data) {
    if (data == null) {
        return null
    }
    if (typeof data === 'string') {
        return data.trim()
    }
    if (data instanceof Array) {
        for (const item of data) {
            trim(item)
        }
    }
    if (typeof data === 'object') {
        for (const key in data) {
            data[key] = trim(data[key])
        }
    }
    return data
}

// 计算天数对应年月日
export function dayToYear(day) {
    if (day < 30) {
        return day + '天'
    } else if (day < 365) {
        let month = Math.floor(day / 30)
        let days = Math.round((day / 30 - month) * 30)
        if (days == 0) {
            return month + '个月'
        } else {
            return month + '个月' + days + '天'
        }
    } else {
        let year = Math.floor(day / 365)
        let month = Math.floor(((day / 365 - year) * 365) / 30)
        let days = Math.round((((day / 365 - year) * 365) / 30 - month) * 30)
        if (month == 0 && days == 0) {
            return year + '年'
        } else if (days == 0) {
            return year + '年' + month + '个月'
        } else {
            return year + '年' + month + '个月' + days + '天'
        }
    }
}

/**
 * 防抖原理：一定时间内，只有最后一次操作，再过wait毫秒后才执行函数
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
export function debounce(func, wait = 500, immediate = false) {
    // 清除定时器
    if (timeout !== null) clearTimeout(timeout)
    // 立即执行，此类情况一般用不到
    if (immediate) {
        var callNow = !timeout
        timeout = setTimeout(function () {
            timeout = null
        }, wait)
        if (callNow) typeof func === 'function' && func()
    } else {
        // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
        timeout = setTimeout(function () {
            typeof func === 'function' && func()
        }, wait)
    }
}
