/**
 * 金额转中文大写
 * 规则:
 * 1. 2位小数，小数到分，就是几角几分，没有元，没有整。例如：123.23（壹佰贰拾叁元贰角叁分）
 * 2. 1位小数，小数到角，就是整；例如：123.2（壹佰贰拾叁元贰角整）
 * 3. 没有小数，则直接就是xxx元整；例如：10000.00（壹万元整）
 */

/**
 * 将数字金额转换为中文大写金额
 * @param {number|string} money 数字金额
 * @returns {string} 中文大写金额
 */
export function convertToChinese(money) {
  if (money === undefined || money === null || money === '') {
    return '';
  }

  // 数字转换为字符串
  money = parseFloat(money);
  if (isNaN(money)) {
    return '';
  }

  // 对金额进行四舍五入，保留两位小数
  money = Math.round(money * 100) / 100;
  money = money.toString();

  // 汉字的数字
  const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  // 基本单位
  const cnIntRadice = ['', '拾', '佰', '仟'];
  // 对应整数部分扩展单位
  const cnIntUnits = ['', '万', '亿', '兆'];
  // 对应小数部分单位
  const cnDecUnits = ['角', '分'];
  // 整数金额时后面跟的字符
  const cnInteger = '整';
  // 整型完以后的单位
  const cnIntLast = '元';
  
  // 金额整数部分
  let integerNum;
  // 金额小数部分
  let decimalNum;
  // 输出的中文金额字符串
  let chineseStr = '';
  
  // 分离金额的整数和小数部分
  if (money.indexOf('.') === -1) {
    integerNum = money;
    decimalNum = '';
  } else {
    const parts = money.split('.');
    integerNum = parts[0];
    decimalNum = parts[1];
  }
  
  // 处理整数部分
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0;
    const intLen = integerNum.length;
    for (let i = 0; i < intLen; i++) {
      const n = integerNum.substr(i, 1);
      const p = intLen - i - 1;
      const q = Math.floor(p / 4);
      const m = p % 4;
      if (n === '0') {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m === 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  } else {
    // 整数部分为0
    if (decimalNum !== '') {
      chineseStr = '';
    } else {
      chineseStr = cnNums[0] + cnIntLast;
    }
  }
  
  // 处理小数部分
  if (decimalNum !== '') {
    const decLen = decimalNum.length;
    for (let i = 0; i < decLen && i < 2; i++) {
      const n = decimalNum.substr(i, 1);
      if (n !== '0') {
        chineseStr += cnNums[parseInt(n)] + cnDecUnits[i];
      } else if (n === '0' && i === 0 && decimalNum.length > 1 && decimalNum.substr(1, 1) !== '0') {
        // 当角位是0但分位不是0时，需要加"零"
        chineseStr += cnNums[0];
      }
    }
  }
  
  // 根据规则处理结尾
  if (decimalNum === '' || (decimalNum.length === 1 && decimalNum === '0') || (decimalNum.length === 2 && decimalNum === '00')) {
    // 没有小数或小数全为0，添加"整"
    chineseStr += cnInteger;
  } else if (decimalNum.length === 1 || (decimalNum.length === 2 && decimalNum.substr(1, 1) === '0')) {
    // 只有1位小数或第2位是0，添加"整"
    if (decimalNum.substr(0, 1) !== '0') {
      chineseStr += cnInteger;
    }
  }
  
  return chineseStr;
}

export default {
  convertToChinese
}; 