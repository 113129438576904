<template>
  <div
    :class="[
      'fast-input-item-wrapper',
      className,
      hasLabel ? 'hasLabel' : '',
      isButton ? 'isButton' : '',
      isRadio ? 'isRadio' : '',
      readonly ? 'readonly' : '',
      inputSearchHasWidth ? 'inputSearchHasWidth' : '',
      isDescription ? 'isDescription' : '',
      isUnderline ? 'isUnderline' : '',
    ]"
    :style="inputStyle"
    @keydown="onKeydown"
  >
    <a-form-item
      :class="['form-item-flex']"
      :label-col="{ flex: `0 0 ${labelWidth || '130px'}` }"
      :wrapper-col="isDescription ? { flex: 1 } : { flex: `0 0 ${contentWidth}` }"
      :colon="colon"
    > 
      <span
        :id="config.dataIndex"
        slot="label"
        class="form-item-label-box"
        v-if="label && config?.isTodo"
      >
        <slot :name="config.customLabelRender" :label="label">
          {{ config.serialNo || '' }}{{ label }}
        </slot>
      </span>
      <span slot="label" class="form-item-label-box" v-else-if="label">
        <slot :name="config.customLabelRender" :label="label">
          {{ config.serialNo || '' }}{{ label }}
        </slot>
      </span>
      <div class="form-item-controls-wrapper" :class="[config.className ?? '']">
        <fast-input
          :config="config"
          @query="emitQuery"
          :isRow="isRow"
          v-decorator="decorator"
          @change="onChange"
          :formConfig="formConfig"
          v-bind="config.elementProps || {}"
        >
          <template v-slot:[scopedSlots.customRender]="slotProps">
            <slot :name="scopedSlots.customRender" v-bind="slotProps"></slot>
          </template>
        </fast-input>

        <!-- 由于新增tooltip标识，新增flex 需要和表单项变为一行 -->
        <span class="tooltip-container" v-if="tooltip">
          <a-tooltip :title="tooltip">
            <a-icon type="info-circle" />
          </a-tooltip>
        </span>
      </div>
      <div v-if="desc" class="form-item-controls-desc">
        <span>{{ desc }}</span>
      </div>
    </a-form-item>
  </div>
</template>

<script>
import { KEY_CODE_ENTER } from './constants';
import _ from 'lodash';
import FastInput from './FastInput.vue';
const describeColorType = {
  yn: { bgColor: '#FCF3EC', borderColor: '#FF700A' },
  green: { bgColor: '#E9F8F4', borderColor: '#12E6AD' },
  orange: { bgColor: '#FFF5EA', borderColor: '#FF9015' },
  blue: { bgColor: '#E3EEFA', borderColor: '#1485FF' },
  purple: { bgColor: '#F1F0FD', borderColor: '#2614FF' },
  red: { bgColor: '#FCEEED', borderColor: '#FF2414' },
  default: { bgColor: '#FFF5EA', borderColor: '#FF9015' },
};
export default {
  components: {
    FastInput,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: 'select',
          dataIndex: 'classify',
          placeholder: '请输入分类名称',
          width: 160,
          icon: {
            type: 'search',
            slot: 'suffix',
          },
          tooltip: '',
        };
      },
    },
    formConfig: {
      type: Object,
    },
    isRow: {
      type: Boolean,
    },
  },
  computed: {
    isUnderline() {
      return this.config.disabledField && this.config.isUnderline;
    },
    // 是否拥有label
    hasLabel() {
      return !_.isUndefined(this.config.label);
    },
    isButton() {
      return this.config.type === 'button';
    },
    isRadio() {
      return this.config.type === 'radio';
    },
    // 文字说明
    isDescription() {
      return this.config?.type === 'description';
    },
    readonly() {
      return this.config.readonly ? 'readonly' : '';
    },
    // 特别处理inputSearch宽度
    inputSearchHasWidth() {
      return this.config.width ? 'inputSearchHasWidth' : '';
    },
    labelWidth() {
      let labelWidth = '0';
      if (this.isRow) {
        if (this.hasLabel) {
          // 分析出数字+字母的个数 分析出汉字的个数
          const reg = /[0-9a-zA-Z]/g;
          const regFont = /[\u4e00-\u9fa5]/g;
          const num = this.config.label.match(reg)?.length ?? 0;
          const numFont = this.config.label.match(regFont)?.length ?? 0;
          labelWidth = `${num * 8 + numFont * 14 + 24}px`;
        }
      } else {
        const width = this.config?.labelWidth ?? this.formConfig?.labelWidth ?? '';
        labelWidth = _.isNumber(width) ? width + 'px' : width;
      }

      return labelWidth;
    },
    contentWidth() {
      const width = this.config?.rangeContentWidth ?? this.config?.width ?? '';
      const contentWidth = _.isNumber(width) ? `${width}px` : width;
      if (this.isRow) {
        return width ? `calc(100% - ${this.labelWidth})` : 'auto';
      } else {
        return width ? contentWidth : 'calc(100% - 155px)';
      }
    },
    inputStyle() {
      let baseStyle = {};
      let width = '';
      if (this.isRow) {
        // 如果是数字就加上px，如果带%就不加，如果带px也不加
        let configWidth;
        if (this.config.width) {
          configWidth = this.config?.width
            ? _.isNumber(this.config.width)
              ? `${this.config.width}px`
              : this.config.width
            : '100%';
        }

        width = configWidth ? `calc(${configWidth} )` : 'max-content';
      } else {
        width = this.config?.width ? `calc(${this.config?.width} - 40px)` : 'calc(100% - 20px)';
      }

      const align = this.itemsAlign;
      baseStyle = {
        ...baseStyle,
        ...align,
        width,
        '--content-width': `${this.contentWidth}`,
      };

      // 如果是动态中表单中的文字说明类型，设置背景色
      if (this.isDescription) {
        this.config.color = this.config.color || 'default';
        let { bgColor, borderColor } =
          describeColorType[this.config.color] || describeColorType['default'];
        baseStyle.backgroundColor = bgColor;
        // baseStyle.border = `1px solid ${borderColor}`;

        baseStyle.borderRadius = '2px';
        baseStyle.marginBottom = '12px';
        baseStyle.width = '100%';
      }

      return baseStyle;
    },
    colon() {
      return this.config?.colon ?? this.formConfig?.colon ?? true;
    },
    label() {
      return this.config.label || '';
    },
    className() {
      return this.config.class || '';
    },
    isSlots() {
      return _.isObject(this.config.scopedSlots);
    },
    hasIcon() {
      return this.config?.icon?.type;
    },
    isVisible() {
      return typeof this.config?.visible === 'boolean' ? this.config.visible : true;
    },
    isShow() {
      return typeof this.config?.show === 'boolean' ? this.config.show : true;
    },
    // 判断逻辑结束
    options() {
      return this.config?.options || [];
    },
    disabled() {
      return this.config?.disabled || false;
    },
    size() {
      return this.config?.size ?? 4;
    },
    isDefaultChecked() {
      return this.config?.defaultChecked || false;
    },
    iconType() {
      return this.config?.icon?.type || '';
    },
    iconSuffix() {
      return this.config?.icon?.suffix || 'suffix';
    },
    buttonType() {
      return this.config.buttonType || 'primary';
    },
    dataIndex() {
      return this.config.dataIndex;
    },
    rules() {
      return this.config?.rules || [];
    },
    desc() {
      return this.config?.desc || '';
    },
    validateTrigger() {
      return this.config?.validateTrigger || 'change';
    },
    valueFormat() {
      return this.config?.valueFormat ?? 'YYYY-MM-DD HH:mm:ss';
    },
    initialValue() {
      return this.config?.initialValue;
    },
    placeholder() {
      return this.config?.placeholder || '';
    },
    tooltip() {
      return this.config?.tooltip || '';
    },
    //时间范围提示词
    rangePlaceholder() {
      return this.config?.rangePlaceholder || ['开始日期', '结束日期'];
    },
    allowClear() {
      return this.config?.allowClear ?? true;
    },
    addonAfter() {
      return this.config?.addonAfter || false;
    },
    scopedSlots() {
      return this.config?.scopedSlots || {};
    },
    text() {
      return this.config.text;
    },
    // 非config值传
    decorator() {
      return [
        this.dataIndex,
        {
          validateTrigger: this.validateTrigger,
          rules: this.rules,
          initialValue: this.initialValue,
        },
      ];
    },
    itemsAlign() {
      const align = {};
      if (this.config?.align) {
        switch (this.config.align) {
          case 'right':
            align.marginLeft = 'auto';
            break;
          case 'left':
            align.marginRight = 'auto';
            break;
        }
      }
      return align;
    },
    // input maxLength
    maxLength() {
      return this.config?.maxLength ?? -1;
    },
    // inputNumber formatter
    formatter() {
      return (
        this.config?.formatter ??
        function (res) {
          return res;
        }
      );
    },
    // inputNumber parser
    parser() {
      return (
        this.config?.parser ??
        function (res) {
          return res;
        }
      );
    },
    // inputNumber inputNumberMin
    inputNumberMin() {
      return this.config?.min ?? 1;
    },
    // inputNumber inputNumberMax
    inputNumberMax() {
      return this.config?.max ?? Infinity;
    },

    //select多选最多显示多少个 tag
    maxTagCount() {
      return this.config?.maxTagCount ?? 2;
    },
  },
  methods: {
    isObject(arg) {
      return _.isObject(arg);
    },
    emitQuery() {
      this.$emit('query');
    },
    onClick() {
      if (this.config?.onClick) {
        this.$nextTick(() => {
          this.config.onClick();
        });
      }
    },
    onBlur() {
      if (this.config?.onBlur) {
        this.$nextTick(() => {
          // stepsFastForm中更新方法比较晚，sync allFormData中的值此时还没更新，暂时先用这种方法解决
          this.$nextTick(() => {
            this.config.onBlur(...arguments);
          });
        });
      }
    },
    onChange() {
      console.log("🚀 ~ this.$nextTick ~ arguments:", arguments)
      if (this.config?.onChange) {
        this.$nextTick(() => {
          // stepsFastForm中更新方法比较晚，sync allFormData中的值此时还没更新，暂时先用这种方法解决
          this.$nextTick(() => {
            this.config.onChange(...arguments);
          });
        });
      }
    },
    onKeydown(e) {
      if (e.code === KEY_CODE_ENTER) {
        this.emitQuery();
      }
    },
    //模糊搜索
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.fast-input-item-wrapper /deep/ {
  > .form-item-flex > .ant-form-item-control-wrapper {
    width: var(--content-width);
  }
}
.fast-input-item-wrapper {
  flex: 0 auto;
  margin: 0px 6px 0px 6px;
  /* 高亮闪动动画 */
  @keyframes highlight-animation {
    0% {
      color: #333;
      transform: scale(1); /* 初始缩放 */
      font-weight: normal;
    }
    50% {
      color: red;
      transform: scale(1.3); /* 中间缩放 */
      font-weight: bold;
    }
    100% {
      color: #333;
      transform: scale(1); /* 最终缩放 */
      font-weight: normal;
    }
  }

  .highlight {
    animation: highlight-animation 0.5s ease-in-out 0s 3.5;
  }
}

.form-item-flex {
  display: flex;
  // position: relative;
  // top: 50%;
  // transform: translateY(-50%);
}
.isDescription /deep/ .ant-form-item {
  margin-bottom: 0px !important;
  padding: 16px;
  .form-item-controls-wrapper {
    min-height: unset;
  }
}
.form-item-label-box {
  max-width: 95%; // 建议80%，太长就会超出内容
  display: inline-block;
  height: auto;
  white-space: wrap;
  text-align: left;
  vertical-align: bottom; // 这是为了让整体的字往下移动一点
}
.tooltip-container {
  margin-left: 10px;
}
.default-tips {
  font-size: 12px;
  color: #9a9a9a;
  margin-top: -19px;
}
.form-item-controls-wrapper {
  // 由于新增tooltip标识，新增flex 需要和表单项变为一行
  display: flex;
  align-items: center;
  min-height: 40px;
}
.openstore-template {
  width: 337px;
}
.form-item-controls-desc {
  min-height: 24px;
  line-height: 16px;
  margin-top: 6px;
  font-size: 12px;
  color: #8e8d8d;
}
.text-show {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
</style>
<style>
.isUnderline,
.isUnderline .fast-text-wrapper {
  text-decoration: line-through;
}
</style>
