import Vue from 'vue';

export default function useMessage() {
  const confirm = Vue.prototype.$confirm;
  const message = Vue.prototype.$message;
  const elementAlert = Vue.prototype.$alert;
  const notify = Vue.prototype.$notify;

  return { confirm, message, elementAlert, notify };
}
