export default {
  state: {
    selectedAndFullData: {
      pushSelectedData: [],
      fullData: [],
    },
    selectedAndFullData_view: {
      pushSelectedData: [],
      fullData: [],
    },
    isAsyncConditionFormItems: [],
    flattenedItems: [],
    nodeMap: new Map(),
    isEdit: null,
    selectedNode: localStorage.getItem('selectedNode')
      ? JSON.parse(localStorage.getItem('selectedNode'))
      : {},
    selectFormItem: null,
    // 当前节点树所在的流程模板
    design: {
      /**
       * snapshotId 快照id
       * templateId 模板唯一id
       */
    },
    subTemplateList: [], //当前主流程下的子流程list
    mainTemplateInfo: localStorage.getItem('mainTemplateInfo')
      ? JSON.parse(localStorage.getItem('mainTemplateInfo'))
      : {
          //主流程info
          /**
           * snapshotId 快照id
           * templateId 模板唯一id
           */
        },
    subTemplateBaseInfo: {}, //子流程基础配置info
    activeTabKey: '', //设置当前tab
    //复制的节点列表
    copyNodeList: [],

    saveSubFormList: [], // 校验全局子集表单事件队列 { id: 'notify', callback: Promise }

    componentlinkageConfig: [], // 组件关联数据
    cityApiStoreCode: '',
  },
  mutations: {
  },
  mutations: {
    setSelectedAndFullData(state, val) {
      state.selectedAndFullData = val;
    },
    setSelectedAndFullData_view(state, val) {
      state.selectedAndFullData_view = val;
    },
    setCityApiStoreCode(state, val) {
      state.cityApiStoreCode = val;
    },
    setAsyncConditionFormItems(state, val) {
      state.isAsyncConditionFormItems = val;
    },
    setFlattenedItems(state, val) {
      state.flattenedItems = val;
    },
    selectedNode(state, val) {
      state.selectedNode = val;
      localStorage.setItem('selectedNode', JSON.stringify(val));
    },
    loadForm(state, val) {
      state.design = val;
    },
    setMainTemplateInfo(state, val) {
      state.mainTemplateInfo = val;
      localStorage.setItem('mainTemplateInfo', JSON.stringify(val));
    },
    setSubTemplateList(state, val) {
      state.subTemplateList = val;
    },
    setSubTemplateBaseInfo(state, val) {
      state.subTemplateBaseInfo = val;
    },
    setActiveTabKey(state, val) {
      state.activeTabKey = val;
    },
    setIsEdit(state, val) {
      state.isEdit = val;
    },
    //设置复制的节点列表
    setCopyNodeList(state, val) {
      //copyNodeList最多存储5个节点，新的节点放在最前面
      if (state.copyNodeList.length >= 5) {
        state.copyNodeList.pop();
      }
      state.copyNodeList.unshift(val);
    },
    removeCopyNode(state, val) {
      state.copyNodeList.splice(val, 1);
    },
    clearCopyNodeList(state) {
      state.copyNodeList = [];
    },

    addSaveSubFormList(state, data) {
      let index = state.saveSubFormList.findIndex((item) => {
        return item.id === data.id;
      });
      if (index === -1) {
        state.saveSubFormList.push(data);
      } else {
        // 替换之前的模块id - 方法才是最新的
        state.saveSubFormList.splice(index, 1, data);
      }
    },
    setComponentlinkageConfig(state, data) {
      state.componentlinkageConfig = data;
    },
    destorySaveList(state) {
      state.saveSubFormList = [];
    },
  },
  getters: {},
  actions: {
    callSaveSubForm({ state }) {
      let list = state.saveSubFormList.map((form) => {
        return form.callback();
      });
      return Promise.all(list);
    },
  },
  modules: {},
};
