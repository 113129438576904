<template>
  <div class="fast-text-wrapper">
    <!-- <TextCom v-if="isShowText" :config="configRef" :value="valueRef"></TextCom> -->
    <RentComputed v-if="isShowRentComputed" :config="configRef" :value="valueRef" :disabled="true"></RentComputed>
    <UserPicker v-else-if="isShowUserPicker" :config="configRef" :value="valueRef"></UserPicker>
    <div v-else-if="isShowDateTimeRangeText">{{ transformRange(valueRef) }}</div>
    <div v-else-if="isArea">{{ transformArea(valueRef) }}</div>
    <div v-else-if="isOptionSelect">{{ valueRef ? valueRef.label : '' }}</div>
    <div v-else-if="isInputMap">{{ valueRef?.[2] }}</div>
    <div v-else-if="isMultipleSelect">{{ (valueRef || []).join('，') }}</div>
    <div v-else-if="isAmountInput" class="amount-wrapper">
      {{ valueRef }}
      <div v-if="showChinese && valueRef" class="amount-capital">
        大写：{{ convertToChinese(valueRef) }}
      </div>
    </div>
    <div v-else style="word-break: break-all" v-html="getHtml()"></div>
  </div>
</template>
<script setup>
import _ from 'lodash';
// 拿出attr和监听的事件
import { defineProps, toRefs, computed, watch } from 'vue';
import TextCom from './TextComponents/Text/index.vue';
import UserPicker from './TextComponents/TextUserPicker/index.vue';
import { isEmptyValue } from '@/util/index.js';
import RentComputed from './RentComputed/index.vue';
import { convertToChinese } from '@/util/moneyToCapital';
const getHtml = () => {
  if(isEmptyValue(valueRef.value)) return '';
  return (valueRef.value).toString().replace(/\n/g, '<br>');
};
const props = defineProps({
  value: {
    type: [String, Object, Number, Array],
    // default: '',
  },
  config: {
    type: Object,
    default: () => ({}),
  },
});

const { config: configRef, value: valueRef } = toRefs(props);
const oldType = configRef.value.oldType;
// 是否是从'TextInput', 'TextareaInput'变换过来的
const isShowText = computed(() => {
  return ['TextInput', 'TextareaInput'].includes(oldType);
});
// 是否是从userpicker中变换过来的
const isShowUserPicker = computed(() => {
  return ['UserPicker'].includes(oldType);
});

const isShowRentComputed = computed(() => {
  return ['RentComputed'].includes(oldType);
});
// 是否是从'DateTimeRange' 日期区间
const isShowDateTimeRangeText = computed(() => {
  return ['DateTimeRange'].includes(oldType);
});
// 是否是省市区
const isArea = computed(() => {
  return ['Provinces'].includes(oldType);
});
// 是否地图选点
const isInputMap = computed(() => {
  return ['InputMap'].includes(oldType);
});
// 是否开发组别
const isOptionSelect = computed(() => {
  return ['OptionSelect'].includes(oldType);
});
// 是否开多选框
const isMultipleSelect = computed(() => {
  return ['MultipleSelect'].includes(oldType);
});
// 是否是金额输入
const isAmountInput = computed(() => {
  return ['AmountInput'].includes(oldType);
});
// 是否显示大写
const showChinese = computed(() => {
  return configRef.value?.showChinese !== false;
});
const transformRange = (val) => {
  return val && val.length === 2 ? val.join(' ~ ') : '';
};
const transformArea = (val) => {
  console.log('🚀 ~ transformArea ~ val:', val);
  let text = '';
  let list = val || [];
  try {
    list = list.map((item) => {
      let result = item;
      try {
        result = JSON.parse(item);
      } catch (error) {
        result = item;
      }

      return result.areaName;
    });
  } catch (error) {
    console.log('🚀 ~ transformArea ~ error:', error);
  }
  text = list && list.length ? list.join('/') : '';
  return text;
};
</script>

<style lang="scss" scoped>
.fast-text-wrapper {
  padding: 12px 0;
  line-height: 1.3;
  // font-size: 14px;
  // margin-bottom: 8px;
  // margin-top: 8px;
  
  .amount-wrapper {
    position: relative;
    width: 100%;
    
    .amount-capital {
      font-size: 12px;
      color: #666;
      line-height: 1;
      margin-top: 6px;
    }
  }

  img {
    width: 12px;
    height: 13px;
    margin-right: 4px;
  }
  span,
  img {
    vertical-align: middle;
  }
}
</style>
