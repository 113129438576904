<template>
  <div class="rent-computed">
    <!-- <template v-if="isShowRadio">
      <el-radio-group v-model="val.type" :disabled="disabled">
        <el-radio v-for="(op, index) in options" :key="index" :label="op.value">
          {{ op.label }}
        </el-radio>
      </el-radio-group>
    </template> -->
    <div>
      <div class="rent-computed-list" v-for="(item, index) in val.list" :key="item.id">
        <el-input
          size="small"
          style="width: 33%"
          :disabled="disabled"
          :placeholder="placeholder"
          v-model="item.money"
        />
        <el-date-picker
          type="daterange"
          size="small"
          style="width: 67%; margin: 0 5px"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :start-placeholder="placeholderArr[0]"
          :end-placeholder="placeholderArr[1]"
          :value="getValue(item)"
          :disabled="disabled"
          @input="changeTime($event, item, index)"
        />
        <!-- 
          v-model="item.time" -->
        <template v-if="isShowBtn && !disabled && !isShowRadio">
          <i class="el-icon- el-icon-circle-plus" @click="addPItem(item, index)"></i>
          <i
            class="el-icon- el-icon-remove-outline"
            v-if="val.list.length > 1"
            @click="removePItem(item, index)"
          ></i>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getListItem } from '@/components-antd/MzFastForm/RentComputed/index.js';
export default {
  name: 'RentComputed',
  props: {
    value: {
      default() {
        return '';
      },
    },
    config: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      options: [
        {
          label: '经营免租期',
          value: '1',
        },
        {
          label: '装修免租期',
          value: '2',
        },
      ],
    };
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    isShowBtn() {
      return true;
    },
    isShowRadio() {
      return !this.config.adaptiveType;
    },
    placeholder() {
      let result = this.config.placeholder;
      if (result) return result;
      return '请填写';
    },
    placeholderArr() {
      let result = this.config.placeholderArr;
      if (Array.isArray(result) && result.length) return result;
      return ['开始时间', '结束时间'];
    },
  },
  methods: {
    addPItem(item, index) {
      this.val.list.push(getListItem());
    },
    removePItem(item, index) {
      if (index > -1) {
        this.val.list.splice(index, 1);
      }
    },

    changeTime(value, item, index) {
      if (Array.isArray(value) && value.length) {
        this.$set(item, 'startTime', value[0]);
        this.$set(item, 'endTime', value[1]);
      } else {
        this.$set(item, 'startTime', '');
        this.$set(item, 'endTime', '');
      }
    },
    getValue(item) {
      if (item.startTime && item.endTime) {
        return [item.startTime, item.endTime];
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.rent-computed {
  .rent-computed-list {
    display: flex;
    align-items: center;
    line-height: 40px;
  }
  .el-icon-circle-plus {
    font-size: 20px;
    color: #ff6b00;
  }
  .el-icon-remove-outline {
    font-size: 20px;
  }

  /deep/.el-input__inner {
    border-radius: 6px;
  }
  /deep/ .el-radio-group label {
    display: inline-block !important;
  }
}
</style>
