export const handlerCity = (data = [], options = {}) => {
  let children = (options && options.children) || 'children';
  const traverseTree = (nodes, level) => {
    nodes.forEach((node) => {
      if (!node[children]) {
        delete node[children];
      }
      if (node[children] && node[children].length > 0) {
        traverseTree(node[children], level + 1);
      }
    });
  };
  traverseTree(data, 1);
  return data;
};
export const getCityId = (list) => {
  if (!list) return list || [];
  let result = list.map((item) => {
    let res = item;
    try {
      res = JSON.parse(item);
    } catch (error) {}
    return typeof res === 'string' ? res : res.areaUid;
  });
  return result;
};

export const findCodes = (treeNode, keys, options) => {
  let children = (options && options.children) || 'children';
  let list = [];
  const traverseTree = (nodes) => {
    nodes.forEach((node) => {
      if (keys.indexOf(node.areaUid) > -1) {
        list.push(node);
      }
      if (node[children] && node[children].length > 0) {
        traverseTree(node[children]);
      }
    });
  };
  traverseTree(treeNode);

  let handleList = list.map((item) => {
    return {
      areaName: item.areaName,
      areaUid: item.areaUid,
      areaType: item.areaType,
    };
  });
  return handleList;
};

export const getMultipleCodes = (list) => {
  if (!list) return list || [];
  let result = list.map((cityItem) => {
    let handleList = [];
    if (cityItem.provinceUid) {
      handleList.push(cityItem.provinceUid);
    }
    if (cityItem.cityUid) {
      handleList.push(cityItem.cityUid);
    }
    if (cityItem.areaUid) {
      handleList.push(cityItem.areaUid);
    }
    return handleList;
  });
  return result;
};
export const findMultipleCodes = (treeNode, keys) => {
  let children = 'children';
  let chaceMap = {};
  const traverseTree = (nodes, itemList, result) => {
    if (itemList.length === result.length) return;
    nodes.forEach((node) => {
      if (itemList.indexOf(node.areaUid) > -1) {
        result.push({
          areaName: node.areaName,
          areaUid: node.areaUid,
          areaType: node.areaType,
        });
      }
      if (node[children] && node[children].length > 0) {
        traverseTree(node[children], itemList, result);
      }
    });
    return result;
  };
  let handleList = [];
  for (let index = 0; index < keys.length; index++) {
    const itemList = keys[index];
    let result = [];
    let item = traverseTree(treeNode, itemList, result);
    item && handleList.push(item);
  }

  handleList = handleList.map((lists) => {
    let item = {};
    lists.forEach((cityItem) => {
      if (cityItem.areaType === 1) {
        item.province = cityItem.areaName;
        item.provinceUid = cityItem.areaUid;
      } else if (cityItem.areaType === 2) {
        item.city = cityItem.areaName;
        item.cityUid = cityItem.areaUid;
      } else if (cityItem.areaType === 3) {
        item.area = cityItem.areaName;
        item.areaUid = cityItem.areaUid;
      }
    });
    return item;
  });

  return handleList;
};
