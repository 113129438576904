import request from '../../utils/request';
import Request from '@/libs/request';
import baseUrl from '@/investment/config/baseUrl';
const requestApiFlow = new Request('/api/flow');
const requestApiOmAuth = new Request(baseUrl);
const requestNewApi = new Request();
import { unpack } from '@/util/index';
// ============================================================================海外政策新接口
const requestInvestmentOverseastApi = new Request('/api/sign/api/investmentOverseas');
export function abroadAddSignNew(data) {
  return requestInvestmentOverseastApi.post('/addSign', data);
}
export function abroadGetSignList(params) {
  return requestInvestmentOverseastApi.post('/signList', params);
}
export function abroadCancellationNew(params) {
  return requestInvestmentOverseastApi.post('/cancellation', params);
}
export function abroadChargeBackNew(params) {
  return requestInvestmentOverseastApi.post('/chargeBack', params);
}
export function abroadSignDetailNew(params) {
  return requestInvestmentOverseastApi.post('/signDetail', params);
}
export function abroadExportSignNew(params) {
  return requestInvestmentOverseastApi.post('/exportSign', params, {
    responseType: 'blob',
  });
}

export function abroadApplyUid(params) {
  return requestInvestmentOverseastApi.post('/applyUid', params);
}

// export function policyListNew(params) {
//   return requestInvestmentOverseastApi.post('/policyList', params);
// }
export function abroadContractCountNew(params) {
  return requestInvestmentOverseastApi.post('/contractCount', params);
}
export function abroadInvestmentManagerInfo(params) {
  return requestInvestmentOverseastApi.post('/investmentManagerInfo', params);
}
// export function saveDraftNew(params) {
//   return requestInvestmentOverseastApi.post('/saveDraft', params);
// }
// export function getDraftNew(params) {
//   return requestInvestmentOverseastApi.post('/getDraft', params);
// }
// export function finalPaymentNew(params) {
//   return requestInvestmentOverseastApi.post('/finalPayment', params);
// }
// export function payExtensionRequestNew(params) {
//   return requestInvestmentOverseastApi.post('/payExtensionRequest', params);
// }
// export function policyAreaListNew(params) {
//   return requestInvestmentOverseastApi.post('/policyAreaList', params);
// }
// ============================================================================新接口
const requestInvestmentApi = new Request('/api/sign/api/investmentPolicy');
const authRequest = new Request('/om-auth/api/auth');
export function addSignNew(data) {
  return requestInvestmentApi.post('/addSign', data);
}
export function getSignList(params) {
  return requestInvestmentApi.post('/signList', params);
}
export function cancellationNew(params) {
  return requestInvestmentApi.post('/cancellation', params);
}
export function chargeBackNew(params) {
  return requestInvestmentApi.post('/chargeBack', params);
}
export function signDetailNew(params) {
  return requestInvestmentApi.post('/signDetail', params);
}
export function exportSignNew(params) {
  return requestInvestmentApi.post('/exportSign', params, {
    responseType: 'blob',
  });
}
export function policyListNew(params) {
  return requestInvestmentApi.post('/policyList', params);
}
export function contractCountNew(params) {
  return requestInvestmentApi.post('/contractCount', params);
}
export function investmentManagerInfo(params) {
  return requestInvestmentApi.post('/investmentManagerInfo', params);
}
export function saveDraftNew(params) {
  return requestInvestmentApi.post('/saveDraft', params);
}
export function getDraftNew(params) {
  return requestInvestmentApi.post('/getDraft', params);
}
export function finalPaymentNew(params) {
  return requestInvestmentApi.post('/finalPayment', params);
}
export function payExtensionRequestNew(params) {
  return requestInvestmentApi.post('/payExtensionRequest', params);
}
export function policyAreaListNew(params) {
  return requestInvestmentApi.post('/policyAreaList', params);
}
export function queryAreaNew(params) {
  return authRequest.get('/mac/queryArea', { params });
}
export function queryAllArea(params) {
  return authRequest.get('/mac/allArea', { params });
}
// ============================================================================旧接口
export function policyList(data) {
  return request.post('/investmentPolicy/policyList', data);
}
export function switchPolicy(data) {
  return request.post('/investmentPolicy/switchPolicy', data);
}
export function addPolicy(data) {
  return request.post('/investmentPolicy/addPolicy', data);
}
export function updatePolicy(data) {
  return request.post('/investmentPolicy/updatePolicy', data);
}

// 签约
export function signList(data) {
  return request.post('/investmentPolicy/signList', data);
}
export function signDetail(data) {
  return request.post('/investmentPolicy/signDetail', data);
}
export function addSign(data) {
  return request.post('/investmentPolicy/addSign', data);
}
export function updateSign(data) {
  return request.post('/investmentPolicy/updateSign', data);
}
export function uploadIdCard(data) {
  return request.post('/investmentPolicy/uploadIdCard', data);
}
export function uploadBankCard(data) {
  return request.post('/investmentPolicy/uploadBankCard', data);
}
export function editPreSignQuery(data) {
  return request.post('/investmentPolicy/editPreSignQuery', data);
}
export function exportSign(params) {
  return request.get('/investmentPolicy/exportSign', { params });
}
export function supplementInfo(data) {
  return request.post('/investmentPolicy/supplementInfo', data);
}
export function refund(data) {
  return request.post('/investmentPolicy/refund', data);
}
export function initCloudFlow(data) {
  return request.post('/investmentPolicy/initCloudFlow', data);
}
export function payExtensionRequest(data) {
  return request.post('/investmentPolicy/payExtensionRequest', data);
}
export function financeReview(data) {
  return request.post('/investmentPolicy/financeReview', data);
}
export function cancellation(data) {
  return request.post('/investmentPolicy/cancellation', data);
}
export function investmentReview(data) {
  return request.post('/investmentPolicy/investmentReview', data);
}
export function uploadFiles(data) {
  return request.post('/investmentPolicy/uploadFiles', data);
}
export function goodsList(data) {
  return request.post('/investmentPolicy/goodsList', data);
}
export function reductionCount(data) {
  return request.post('/investmentPolicy/reductionCount', data);
}
export function contractCount(data) {
  return request.post('/investmentPolicy/contractCount', data);
}
export function proxyList(data) {
  return request.post('/investmentPolicy/proxyList', data);
}
export function regionAreaSelectBox(data) {
  return request.post('/investmentPolicy/regionAreaSelectBox', data);
}
export function isFreeFranchiseFee(data) {
  return request.post('/investmentPolicy/isFreeFranchiseFee', data);
}
export function singInfoComplement(data) {
  return request.post('/investmentPolicy/singInfoComplement', data);
}

// 售后订单
export function refundList(data) {
  return request.post('/investmentPolicy/refundList', data);
}
export function exportRefundList(data) {
  return request.post('/investmentPolicy/exportRefundList', data);
}
export function querySignMoney(data) {
  return request.post('/investmentPolicy/querySignMoney', data);
}
export function chargeBack(data) {
  return requestApiOmAuth.post('/investmentPolicy/chargeBack', data);
}
export function childProcessInfo(params) {
  return requestApiFlow.get('investment/childProcessInfo', { params });
}
export function createSync(data) {
  return requestApiFlow.post('instance/subTD/createSync', data);
}
export function chargeBackRefund(data) {
  return request.post('/investmentPolicy/chargeBackRefund', data);
}
export function uploadLicense(data) {
  return request.post('/investmentPolicy/uploadLicense', data);
}
export function goToPay(data) {
  return request.post('/investmentPolicy/goToPay', data);
}
export const importUpdateFileUrl = '/investmentPolicy/importSign';
/**
 * 获取草稿
 * @param {String} key sign为招商签约，policy为政策
 * @param {String} draft JSON序列化后的字符串
 * @returns
 */
export function getDraft(data) {
  return requestInvestmentApi.post('/getDraft', data);
}
/**
 * 保存草稿
 * @param {String} key sign为招商签约，policy为政策
 * @param {String} draft JSON序列化后的字符串
 * @returns
 */
export function saveDraft(data) {
  return requestInvestmentApi.post('/saveDraft', data);
}
/**
 * 退款重新推送
 * @param {String} investmentNo 招商编号
 * @param {String} pushType 1-重推截图，2-重推退款单
 * @returns
 */
export function refundPush(data) {
  return request.post('/investmentPolicy/refundPush', data);
}
/**
 * 退款重新推送
 * @param {String} investmentNo 招商编号
 * @param {String} pushType 1-重推截图，2-重推退款单
 * @returns
 */
/**
 *
 * @param {*} data
 * @returns
 */
export function finalPayment(data) {
  return request.post('/investmentPolicy/finalPayment', data);
}

export function policyAreaList(data) {
  return request.post('/investmentPolicy/policyAreaList', data);
}

// 新增政策新接口
export function policyListPage(data) {
  return requestNewApi.post('/api/sign/api/investmentPolicy/policyListPage', data).then(unpack);
}

// 切换状态
export function setSwitchPolicy(data) {
  return requestNewApi.post('/api/sign/api/investmentPolicy/switchPolicy', data).then(unpack);
}
// 新增
export function addPolicyData(data) {
  return requestNewApi.post('/api/sign/api/investmentPolicy/addPolicy', data).then(unpack);
}
// 更新
export function updatePolicyData(data) {
  return requestNewApi.post('/api/sign/api/investmentPolicy/updatePolicy', data).then(unpack);
}

// 获取适用范围
export function getPolicyAreaList(data) {
  return requestNewApi.post('/api/sign/api/investmentPolicy/policyAreaList', data).then(unpack);
}
export function getRegionAreaSelectBox(data) {
  return requestNewApi
    .post('/api/sign/api/investmentPolicy/regionAreaSelectBox', data)
    .then(unpack);
}
export function getProvinceCityAreaSelectBox(data) {
  return requestNewApi
    .post('/api/sign/api/investmentPolicy/provinceCityAreaSelectBox', data)
    .then(unpack);
}
export function checkPolicySin(params) {
  return requestNewApi.get('/api/sign/api/investmentPolicy/checkPolicySin', { params });
}
