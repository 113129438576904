import { queryShop, developStaffDatasource, getManagerDatasource } from '@/api/workflow';
import useMessage from '@/hooks/useMessage';
// 引入路由ref
import { useRoute } from '@/router';
const { confirm } = useMessage();
export default function useInitDataDispose() {
  const getFoundShopFoundExpertUserPicker = (formsTemp, isEdit = false) => {
    return new Promise(async (resolve, reject) => {
      let shopManagers;
      let experts;
      let shopManageItem, expertItem;

      for (let item of formsTemp) {
        if (item.name === 'UserPicker') {
          if (item.props?.fieldTag == 'ShopManager') {
            shopManageItem = item;
          }
          if (item.props?.fieldTag == 'Expert') {
            expertItem = item;
          }
          if (shopManageItem && expertItem) {
            try {
              const data = (await queryShop()).data;
              shopManagers = data.shopManagers;
              experts = data.experts;
              break;
            } catch (error) {
              resolve(formsTemp);
              break;
            }
          }
        }
      }
      if (shopManageItem) {
        shopManageItem.props.userPicker = shopManagers;
        if (isEdit && shopManageItem?.value?.id) {
          shopManageItem.props.userPicker.push(shopManageItem.value);
        }
      }
      if (expertItem) {
        expertItem.props.userPicker = experts;
      }
      resolve(formsTemp);
    });
  };
  const shopManagerValueChangeSetExpertValue = async (items, formRef) => {
    if (!items || !formRef) return;
    let foundExpertItem, foundShopManagerItem;
    for (let item of items) {
      if (item.oldType == 'UserPicker' && item.type == 'select') {
        if (!foundShopManagerItem && item?.fieldTag == 'ShopManager') {
          foundShopManagerItem = item;
        }
        if (!foundExpertItem && item?.fieldTag == 'Expert') {
          foundExpertItem = item;
        }
        if (foundShopManagerItem && foundExpertItem) {
          break;
        }
      }
    }
    if (foundShopManagerItem && foundExpertItem) {
      if (foundExpertItem?.rules) {
        foundExpertItem.rules[0].required = false;
      }
      foundShopManagerItem.onChange = (v) => {
        const selectShopManagerUser = foundShopManagerItem.userPicker.find((i) => i.id === v);
        if (selectShopManagerUser) {
          const currentMasterId = formRef.getFieldValue(foundExpertItem.dataFIndex);
          if (currentMasterId !== selectShopManagerUser.masterId) {
            setTimeout(() => {
              formRef.setFieldsValue({
                [foundExpertItem.dataIndex]: selectShopManagerUser.masterId,
              });
              confirm('店长选择后则不支持变更，请谨慎选择', '提示', { showCancelButton: false });
            }, 100);
          }
        }
      };
      console.log('foundShopManagerItem', foundShopManagerItem);
      console.log('foundExpertItem', foundExpertItem);
    }
  };
  // 开发组别
  const getDevelopmentGroupAssociation = (formsTemp, fields) => {
    let viewFields = [];
    try {
      viewFields = JSON.parse(fields);
    } catch (error) {}
    return new Promise(async (resolve, reject) => {
      let kaifazubie, kaifazongjian, kaifajingli;
      for (let item of formsTemp) {
        if (item.itemCode == 'KFZB' && !kaifazubie) {
          kaifazubie = item;
        }
        if (item.itemCode == 'KFZJ' && !kaifazongjian) {
          kaifazongjian = item;
        }
        if (item.itemCode == 'KFJL' && !kaifajingli) {
          kaifajingli = item;
        }
      }

      for (let item of viewFields) {
        if (item && Array.isArray(item.fields)) {
          for (let field of item.fields) {
            if (field.itemCode == 'KFZB' && !kaifazubie) {
              kaifazubie = field;
            }
            if (field.itemCode == 'KFZJ' && !kaifazongjian) {
              kaifazongjian = field;
            }
            if (field.itemCode == 'KFJL' && !kaifajingli) {
              kaifajingli = field;
            }
          }
        }
      }

      if (kaifazubie) {
        try {
          if (kaifazubie.value?.id && kaifazongjian) {
            let res1 = await developStaffDatasource({ type: 1, parentId: kaifazubie.value?.id });
            let zongjianList = res1.data.dataSourceList;
            if (zongjianList && zongjianList.length) {
              kaifazongjian.userPicker = (zongjianList || []).map((item) => {
                return {
                  ...item,
                  label: item.name,
                  value: item.id,
                };
              });
            }
          }
          if (kaifazubie.value?.id && kaifajingli) {
            let res = await developStaffDatasource({
              type: 2,
              parentId: kaifazongjian.value?.id || '0',
              groupId: kaifazubie.value?.id,
            });
            let jingliList = res.data.dataSourceList;
            if (jingliList && jingliList.length) {
              kaifajingli.props.userPicker = (jingliList || []).map((item) => {
                return {
                  ...item,
                  label: item.name,
                  value: item.id,
                };
              });
            }
          }
        } catch (error) {
          console.log('🚀 ~ returnnewPromise ~ error:', error);
        }
      }
      resolve(formsTemp);
    });
  };
  const developmentGroupAssociation = async (items, formRef, callback) => {
    if (!items || !formRef) return;
    let kaifazubie, kaifazongjian, kaifajingli;
    for (let item of items) {
      if (item.itemCode == 'KFZB' && !kaifazubie) {
        kaifazubie = item;
      }
      if (item.itemCode == 'KFZJ' && !kaifazongjian) {
        kaifazongjian = item;
      }
      if (item.itemCode == 'KFJL' && !kaifajingli) {
        kaifajingli = item;
      }
    }

    if (kaifazubie && kaifazongjian) {
      // kaifazongjian.hasDisabled = true;
      // const { routeRef } = useRoute();
      // // 修改表单经理必填
      // if (routeRef.value?.query?.isMonitor === 'true') {
      //   if (kaifajingli?.rules && kaifajingli.rules[0]) {
      //     kaifajingli.rules[0].required = true;
      //   } else {
      //     kaifajingli.rules = [{ required: true, message: `请选择${kaifajingli.label}` }];
      //   }
      // }
    }
    if (kaifazubie && kaifazongjian) {
      let fn = async (v) => {
        // 清除数据
        kaifazongjian && (kaifazongjian.userPicker = []);
        kaifajingli && (kaifajingli.userPicker = []);

        let res = await developStaffDatasource({ type: 1, parentId: v });
        let zongjianList = res.data.dataSourceList;
        let kaifazongjianValue = '',
          kaifajingliValue = '';
        if (zongjianList && zongjianList.length) {
          kaifazongjian.userPicker = (zongjianList || []).map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.id,
            };
          });
          kaifazongjian.options = kaifazongjian.userPicker;
          let zongjianItem = zongjianList[0];
          kaifazongjianValue = zongjianItem.id;
          if (zongjianItem && kaifajingli) {
            const currentMasterId = formRef.getFieldValue(kaifazongjian.dataFIndex);
            if (currentMasterId !== zongjianItem.id) {
              let res = await developStaffDatasource({
                type: 2,
                parentId: zongjianItem.id,
                groupId: v,
              });
              let jingliList = res.data.dataSourceList;
              if (jingliList && jingliList.length) {
                kaifajingli.userPicker = (jingliList || []).map((item) => {
                  return {
                    ...item,
                    label: item.name,
                    value: item.id,
                  };
                });
                kaifajingli.options = kaifajingli.userPicker;
              }
            }
          }
        }

        if (typeof callback === 'function' && kaifazongjian) callback(kaifazongjian);
        if (typeof callback === 'function' && kaifajingli) callback(kaifajingli);
        // setTimeout(() => {
        //   let obj = {
        //     [kaifazongjian.dataIndex]: kaifazongjianValue,
        //   };
        //   if (kaifajingli) {
        //     obj[kaifajingli.dataIndex] = kaifajingliValue;
        //   }
        //   formRef.setFieldsValue(obj);
        // }, 100);
      };
      kaifazubie.onChange = fn;
    }
  };
  // 招商组别
  const getInvestmentGroupAssociation = (formsTemp, fields) => {
    let viewFields = [];
    try {
      viewFields = JSON.parse(fields);
    } catch (error) {}
    return new Promise(async (resolve, reject) => {
      let zhaoshangzubie, zhaoshangzongjian, zhaoshangjingli;
      for (let item of formsTemp) {
        if (item.itemCode == 'ZSZB' && !zhaoshangzubie) {
          zhaoshangzubie = item;
        }
        if (item.itemCode == 'ZSZJ' && !zhaoshangzongjian) {
          zhaoshangzongjian = item;
        }
        if (item.itemCode == 'ZSJL' && !zhaoshangjingli) {
          zhaoshangjingli = item;
        }
      }

      for (let item of viewFields) {
        if (item && Array.isArray(item.fields)) {
          for (let field of item.fields) {
            if (field.itemCode == 'ZSZB' && !zhaoshangzubie) {
              zhaoshangzubie = field;
            }
            if (field.itemCode == 'ZSZJ' && !zhaoshangzongjian) {
              zhaoshangzongjian = field;
            }
            if (field.itemCode == 'ZSJL' && !zhaoshangjingli) {
              zhaoshangjingli = field;
            }
          }
        }
      }

      if (zhaoshangzubie && zhaoshangzongjian && zhaoshangjingli) {
        try {
          // 招商总监
          if (zhaoshangzubie.value?.id && zhaoshangzongjian) {
            let res1 = await getManagerDatasource({ type: 1, id: zhaoshangzubie.value?.id });
            let zongjianList = res1.result;
            if (zongjianList && zongjianList.length) {
              zhaoshangzongjian.userPicker = (zongjianList || []).map((item) => {
                return {
                  ...item,
                  label: item.name,
                  value: item.id,
                };
              });
            }
          }
          // 招商经理
          if (zhaoshangzubie.value?.id && zhaoshangjingli) {
            let res = await getManagerDatasource({
              type: 2,
              id: zhaoshangzubie.value?.id,
            });
            let jingliList = res.result;
            if (jingliList && jingliList.length) {
              zhaoshangjingli.props.userPicker = (jingliList || []).map((item) => {
                return {
                  ...item,
                  label: item.name,
                  value: item.id,
                };
              });
            }
          }
        } catch (error) {
          console.log('🚀 ~ returnnewPromise ~ error:', error);
        }
      }
      resolve(formsTemp);
    });
  };
  const investmentGroupAssociation = async (items, formRef, callback) => {
    if (!items || !formRef) return;
    let zhaoshangzubie, zhaoshangzongjian, zhaoshangjingli;
    for (let item of items) {
      if (item.itemCode == 'ZSZB' && !zhaoshangzubie) {
        zhaoshangzubie = item;
      }
      if (item.itemCode == 'ZSZJ' && !zhaoshangzongjian) {
        zhaoshangzongjian = item;
      }
      if (item.itemCode == 'ZSJL' && !zhaoshangjingli) {
        zhaoshangjingli = item;
      }
    }

    if (zhaoshangzubie && zhaoshangzongjian) {
      // zhaoshangzongjian.hasDisabled = true;
      // const { routeRef } = useRoute();
      // // 修改表单经理必填
      // if (routeRef.value?.query?.isMonitor === 'true') {
      //   if (zhaoshangjingli?.rules && zhaoshangjingli.rules[0]) {
      //     zhaoshangjingli.rules[0].required = true;
      //   } else {
      //     zhaoshangjingli.rules = [{ required: true, message: `请选择${zhaoshangjingli.label}` }];
      //   }
      // }
    }
    if (zhaoshangzubie && zhaoshangzongjian) {
      let fn = async (v) => {
        // 清除数据
        zhaoshangzongjian && (zhaoshangzongjian.userPicker = []);
        zhaoshangjingli && (zhaoshangjingli.userPicker = []);

        let res = await getManagerDatasource({ type: 1, id: v });
        let zongjianList = res.result;
        let zhaoshangzongjianValue = '',
          zhaoshangjingliValue = '';
        if (zongjianList && zongjianList.length) {
          zhaoshangzongjian.userPicker = (zongjianList || []).map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.id,
            };
          });
          zhaoshangzongjian.options = zhaoshangzongjian.userPicker;
          let zongjianItem = zongjianList[0];
          zhaoshangzongjianValue = zongjianItem.id;
          if (zongjianItem && zhaoshangjingli) {
            const currentMasterId = formRef.getFieldValue(zhaoshangzongjian.dataFIndex);
            if (currentMasterId !== zongjianItem.id) {
              let res = await getManagerDatasource({
                type: 2,
                // parentId: zongjianItem.id,
                id: v,
              });
              let jingliList = res.result;
              if (jingliList && jingliList.length) {
                zhaoshangjingli.userPicker = (jingliList || []).map((item) => {
                  return {
                    ...item,
                    label: item.name,
                    value: item.id,
                  };
                });
                zhaoshangjingli.options = zhaoshangjingli.userPicker;
              }
            }
          }
        }

        if (typeof callback === 'function' && zhaoshangzongjian) callback(zhaoshangzongjian);
        if (typeof callback === 'function' && zhaoshangjingli) callback(zhaoshangjingli);
        // setTimeout(() => {
        //   let obj = {
        //     [zhaoshangzongjian.dataIndex]: zhaoshangzongjianValue,
        //   };
        //   if (zhaoshangjingli) {
        //     obj[zhaoshangjingli.dataIndex] = zhaoshangjingliValue;
        //   }
        //   formRef.setFieldsValue(obj);
        // }, 100);
      };
      zhaoshangzubie.onChange = fn;
    }
  };
  return {
    shopManagerValueChangeSetExpertValue,
    getFoundShopFoundExpertUserPicker,
    developmentGroupAssociation,
    getDevelopmentGroupAssociation,
    getInvestmentGroupAssociation,
    investmentGroupAssociation,
  };
}
