<template>
  <div class="fast-description-wrapper">
    <div>{{ configRef.serialNo || "" }}{{ configRef.value }}</div>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from 'vue';

const props = defineProps({
  config: {
    type: Object,
    default: () => ({}),
  },
});

const { config: configRef, value: valueRef } = toRefs(props);
</script>

<style lang="scss" scoped>
.fast-description-wrapper {
  // font-size: 14px;
  // line-height: 20px;
  // color: rgb(16, 27, 56);

  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #1a1a1a;
  line-height: 22px;
}
</style>
