import Vue, { getCurrentInstance, ref } from 'vue';
import store from '../store';
import Router from 'vue-router';
import routes from './routes';
import { Message } from 'element-ui';
import { getToken, removeToken } from '@/util/cookie';
import { getMenu, getUserInfo, featchTmpAuthPhoneList } from '@/api/autho';
import { getUserMenu } from '@/api/autho/menu';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject) return originalPush.call(this, location, resolve, reject);
  return originalPush.call(this, location).catch((e) => {});
};

Vue.use(Router);

const router = new Router({
  base: '/insight360/',
  routes,
  mode: 'history',
});

let isFirstLoad = true;

const formatMenuField = (menu) => {
  return {
    id: menu.id + '',
    name: menu.name + '',
    path: menu.path + '',
  };
};
const findTreeItem = (tree, path) => {
  function findNodeByPath(tree, path) {
    for (let node of tree) {
      if (node.path === path) {
        return node; // 找到了目标节点
      }
      if (node.children && node.children.length > 0) {
        const foundNode = findNodeByPath(node.children, path);
        if (foundNode) {
          return foundNode; // 子树中找到了目标节点
        }
      }
    }
    return null; // 没有找到目标节点
  }
  return findNodeByPath(tree, path);
};

const getNewMenus = () => {
  return getUserMenu().then((res) => {
    if (res.data.succ) {
      const data = res?.data?.result?.find((el) => el.path == '/admin')?.children ?? [];
      const arrTree = treeToArr(data);
      const permissions = arrTree.filter((el) => el.type == 'permission').map((el) => el.path);
      const permissionsRouters = [
        ...new Set(arrTree.filter((el) => el.type == 'menu').map((el) => el.path)),
      ];
      store.commit('setPermissions', permissions);
      store.commit('setPermissionsRouters', permissionsRouters);
      store.commit('setNewMenus', handleTreePath(data));
      store.commit('setSourceMenus', data);
      return data;
    } else {
      return false;
    }
  });
};

const treeToArr = (data, pid, res = []) => {
  data.forEach((el) => {
    res.push(el);
    if (el.children && el.children.length) {
      treeToArr(el.children, el.id, res);
    }
  });
  return res;
};

const getTmpAuthPhoneList = () => {
  return featchTmpAuthPhoneList().then((res) => {
    if (res.data.succ === 1) {
      store.commit('setTmpAuthPhoneList', res.data.result ?? []);
      return res.data.result;
    } else {
      return false;
    }
  });
};

const getUser = () => {
  return getUserInfo().then((res) => {
    if (!res.data.code) {
      store.commit('SET_STORE_INFO', res.data.data.store);
      store.commit('SET_TENANT_INFO', res.data.data.tenant);
      store.commit('SET_USERINFO_INFO', res.data.data.userInfo || {});
      store.commit('SET_ROLE_INFO', res.data.data.roles || {});
      return res.data.data;
    } else {
      Message.error(res?.data?.message ?? '获取用户信息失败');
      return false;
    }
  });
};

const getTree = () => {
  const treeListData = store.state.BIV2.treeListData;
  // console.log('treeListData===>', treeListData, store);
  store.dispatch('BIV2/getListData');
};

const handleTreePath = (data, ids = [0]) => {
  data.forEach((el) => {
    const children = el.children.filter((item) => item.type == 'menu');
    Object.assign(el, { ids: ids.concat([el.id]), children });
    if (children && children.length) {
      handleTreePath(children, el.ids);
    }
  });
  return data;
};

//查找菜单
// const findMenuInfo = (key, value, not, source, childrenKey = 'children') => {
//   let reslute = {};
//   const handleFind = (menuItem, modules) => {
//     menuItem.find((item) => {
//       if (Object.keys(reslute).length) return;
//       if (typeof item[key] === 'string') {
//         item[key] = item[key].trim();
//       }
//       let flag = not ? item[key] != value : item[key] == value;
//       if (flag) {
//         //判断isAuthed属性的时候需要判断targetUrl
//         if (!item.targetUrl || item.targetUrl == '') {
//           item[childrenKey] && handleFind(item[childrenKey], modules);
//         } else {
//           reslute = item;
//         }
//       } else if (item[childrenKey]) {
//         handleFind(item[childrenKey], modules);
//       }
//     });
//   };

//   for (let modules in source) {
//     if (Object.keys(reslute).length) return reslute;
//     let menuItem = source[modules];
//     handleFind(menuItem, modules);
//   }
//   return reslute;
// };

const getAuthMeun = (data) => {
  let targetAuthUrl;
  getUrl(data);
  return targetAuthUrl;
  function getUrl(data) {
    data.some((el) => {
      if (el.children && el.children.length) {
        getUrl(el.children);
      } else {
        if (!targetAuthUrl) targetAuthUrl = el.path;
      }
    });
  }
};
const isMenuChildPage = (path) => {
  const permissionMap = {
    '/workflow/buildStore/detail': ['/workflow/buildStore/list', '/workflow/projectMonitor/list'], //项目详情/项目管控详情
    '/workflow/todoList/detail': ['/workflow/todoList/home'], //待办详情
    '/workflow/doneList/detail': ['/workflow/doneList/home'], //已办
    '/workflow/sentList/detail': ['/workflow/sentList/home'], //抄送详情
    '/workflow/readList/detail': ['/workflow/readList/home'], //已阅详情
    '/workflow/dataMng/detail': ['/workflow/dataMng/list'], //报表详情
    '/workflow/dataMng/configDetail': ['/workflow/dataMng/list'], //数据管理-新建数据报表
    '/workflow/template/drawDetail': ['/workflow/template/list'], //流程详情
    '/workflow/projectMonitor/editForm': ['/workflow/projectMonitor/list'], //项目管控-编辑表单
    '/workflow/template/draw': ['/workflow/template/list'], //流程管理-编辑
    '/workflow/buildStore/childProcess/index': [
      '/workflow/projectMonitor/list',
      '/workflow/buildStore/list',
    ], //项目详情/项目管控-发起子流程
    '/workflow/doneList/print': ['/workflow/doneList/home'], //打印
    '/workflow/template/fieldLibrary': ['/workflow/template/draw', '/workflow/template/list'], // 流程管理 / 流程编辑 / 字段库
    '/workflow/template/nodeConfiguration': ['/workflow/template/draw', '/workflow/template/list'], // 流程管理 / 流程编辑 / 节点配置
    '/workflow/template/nodeConfiguration': ['/workflow/buildStore/detail', '/workflow/projectMonitor/list'], // 项目管控 / 项目管控详情 / 节点配置
    '/patrolTask/patrolBaseStoreDetail': ['/patrolTask/baseStore'],
    '/investment/policies-detail': ['/investment/policies'], //招商政策 / 编辑政策
    '/investment/addEditDetailSigning': ['/investment/signingNew'], //招商签约-新增编辑查看
    '/investment/addEditDetailAbroadSigning': ['/investment/abroad-signing'], //招商签约-新增编辑查看
  };
  const menus = permissionMap[path];
  return Array.isArray(menus)
    ? menus.some((menu) => store.state.menu.permissionsRouters.includes(menu))
    : false;
};
const findMenuByPath = (menus, path) => {
  for (const menu of menus) {
    if (menu.path == path) {
      return { id: menu.id + '', path: menu.path, name: menu.name };
    }
    if (menu.children && menu.children.length > 0) {
      const result = findMenuByPath(menu.children, path);
      if (result) {
        return result;
      }
    }
  }
  return '';
};
const updateMenuTab = (to, sourceMenus) => {
  try {
    let menuTab = store.state.menu.menuData.menuTab ?? [];
    let menuIdB = store.state.menu.menuData.menuIdB ?? '';
    let permissionsRouters = store.state.menu.permissionsRouters ?? [];

    // 移除所有不在权限中的tab标签
    menuTab = menuTab
      .filter((item) => permissionsRouters.includes(item.path))
      .map((i) => {
        i.id = i.id + '';
        return i;
      });
    //更新menuIdB
    menuIdB = menuTab.some((item) => item?.id == menuIdB) ? menuIdB : '';
    //如果是菜单则根据新的跳转路径设置激活的menuIdB和添加tab
    if (permissionsRouters.includes(to.path)) {
      const newItem = findMenuByPath(sourceMenus, to.path);
      menuIdB = newItem ? newItem.id + '' : '';

      // 检查是否存在相同 path 的对象
      const exists = menuTab.some((item) => item?.path == newItem?.path);

      // 如果不存在相同 path 的对象，则添加新对象
      if (!exists && newItem) {
        menuTab.push(newItem);
      }
    } else {
      menuIdB = '';
    }

    // 重新设置 store 和 localStorage
    store.state.menu.menuData.menuTab = menuTab;
    store.state.menu.menuData.menuIdB = menuIdB;
    localStorage.setItem('menuTabB', JSON.stringify(menuTab));
    localStorage.setItem('menuIdB', menuIdB + '');
  } catch (e) {
    console.log('updateMenuTab error', e);
  }
};
const handleNextStep = (to, next) => {
  const sourceMenus = store.state.menu.sourceMenus;
  if (store.state.menu.permissionsRouters.includes(to.path) || isMenuChildPage(to.path)) {
    updateMenuTab(to, sourceMenus);
    next();
  } else {
    const targetAuthUrl = getAuthMeun(sourceMenus);
    updateMenuTab({ path: targetAuthUrl }, sourceMenus);
    next(targetAuthUrl || '/login');
  }
};
const toLogin = (next) => {
  removeToken();
  localStorage.clear();
  sessionStorage.clear();
  next('/login');
  isFirstLoad = true;
};
router.beforeEach((to, from, next) => {
  const whiteList = ['/login', '/404']; // 定义白名单
  store.commit('WhetherTree_updata', false);
  store.commit('TREE_ALL', 0);
  // store.commit("TREE_ALL", 230)
  let hasToken = getToken();

  // 拦截旧域名
  const oldDomainWhiteList = ['/oldDomain']; // 定义白名单
  const origin = window.location.origin;
  if (origin.includes('bjddlm.com')) {
    if (oldDomainWhiteList.indexOf(to.path) > -1) {
      next();
    } else {
      next('/oldDomain');
    }
    return;
  }

  if (hasToken) {
    if (isFirstLoad) {
      Promise.all([
        getNewMenus(),
        getUser(),
        /*loadCommonData(), */ getTree(),
        getTmpAuthPhoneList(),
      ])
        .then(([menuData, userData]) => {
          if (!menuData || !menuData.length) {
            Message.error('暂无应用菜单权限');
            toLogin(next);
            return;
          }
          if (menuData && userData) {
            isFirstLoad = false;
            handleNextStep(to, next);
            next();
          } else {
            next('/login');
          }
        })
        .catch((err) => {
          console.log('catch--', err);
          toLogin(next);
        });
    } else {
      updateMenuTab(to, store.state.menu.sourceMenus);
      next();
    }
  } else {
    //   没有token的情况下
    if (whiteList.indexOf(to.path) > -1) {
      //  表示要去的地址在白名单
      next();
    } else {
      if (to.path === '/workflow/todoList/detail') {
        sessionStorage.setItem('defaultPagePath', to.fullPath);
      }
      next('/login');
    }
  }
});

router.onError((error) => {
  const pattern = /Loading chunk (.*)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

// 导出useRouter
export function useRouter() {
  const vm = getCurrentInstance().proxy;
  if (!vm) throw new Error('useRouter must be called in setup function');
  const routerRef = ref(vm.$router);
  return {
    routerRef,
  };
}
// 导出useRoute
export function useRoute() {
  const vm = getCurrentInstance().proxy;
  if (!vm) throw new Error('useRoute must be called in setup function');
  const routeRef = ref(vm.$route);
  return {
    routeRef,
  };
}

export default router;
